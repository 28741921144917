// https://github.com/kenwheeler/slick

import $ from "jquery";
import "slick-carousel/slick/slick";
import "slick-carousel/slick/slick.css";

/****** SLIDERS *******/
const sliders = {
  home: $(".home-slider .inner")
};

sliders.home.slick({
	dots: true,
	arrows: true,
	autoplay: true,
	pauseOnHover: false,
	fade: true,
	prevArrow: $('.home-slider-prev'),
	nextArrow: $('.home-slider-next'),
	customPaging: (slider, i) => {
		let dot;
		let heading;
		let slideNum;
		let desc;
	    let textHeading = $(slider.$slides[i]).find('.single-slide').data('nav-heading');
	    let textDesc = $(slider.$slides[i]).find('.single-slide').data('nav-description');

	    dot = document.createElement('div');
	    slideNum = document.createElement('div');
	    heading = document.createElement('strong');
	    desc = document.createElement('div');

	    heading.innerText = textHeading;
	    desc.innerText = textDesc;
	    slideNum.innerText = `0${ i + 1}`;

	    dot.classList.add('dot');
	    slideNum.classList.add('slide-number');
	    dot.appendChild(slideNum);
	    dot.appendChild(heading);
	    dot.appendChild(desc);

	    return dot;
	},
	appendDots: $('.home-slider-dots')
});