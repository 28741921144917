export default class FloatLabels {
  constructor() {
    this.container = ".form-row input, .form-row textarea, .form-row select";
    this.init();
  }

  init() {
    this.getContainer.forEach(el => {
      if (el.getAttribute("placeholder") == null) {
        el.value != "" && this.addActive(el);
        el.addEventListener("focus", e => this.onFocus(e.target));
        el.addEventListener("keypress", e => this.onKeyPress(e.target));
        el.addEventListener("blur", e => this.onBlur(e.target));
        el.addEventListener("change", e => this.onFocus(e.target));
      }
    });
  }

  get getContainer() {
    return document.querySelectorAll(this.container);
  }

  addActive(input) {
    const parent = input.closest(".form-row");
    parent.classList.add("active");
  }

  removeActive(input) {
    const parent = input.closest(".form-row");
    parent.classList.remove("active");
  }

  onFocus(input) {
    this.addActive(input);
  }

  onKeyPress(input) {
    if (input.value !== "") {
      this.addActive(input);
    }
  }

  onBlur(input) {
    if (input.value === "") {
      this.removeActive(input);
    }
  }
}
